import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { map } from './Map';

const ReplayPathMap = ({ allPositions, positions, index }) => {
  const idAll = 'replayAll';
  const id = 'replay';

  useEffect(() => {
    map.addSource(idAll, {
      'type': 'geojson',
      'data': {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      'source': idAll,
      'id': idAll,
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
        'line-color': '#C5C8F7',
        'line-width': 5,
      },
    });

    map.addSource(id, {
      'type': 'geojson',
      'data': {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      'source': id,
      'id': id,
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
         'line-color': '#000fff',
         'line-width': 5,
      },
    });

    return () => {
      map.removeLayer(idAll);
      map.removeSource(idAll);

      map.removeLayer(id);
      map.removeSource(id);
    };
  }, []);

  useEffect(() => {
    const allCoordinates = allPositions.map(item => [item.longitude, item.latitude]);
    const coordinates = positions.map(item => [item.longitude, item.latitude]);

    map.getSource(idAll).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: allCoordinates,
      },
    });

    if (index !== 0)
    map.getSource(id).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates,
      },
    });

    if (coordinates.length && index === 0) {
      const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        center: [positions[0].longitude, positions[0].latitude],
        zoom: [13],
        padding: { top: 100, bottom: 200, left: 25, right: 25 },
      });
    }
  }, [positions]);

  return null;
}

export default ReplayPathMap;
