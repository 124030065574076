import React, { useState , useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, makeStyles, Paper, Slider, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainToolbar from '../MainToolbar';
import Map from '../map/Map';
import t from '../common/localization';
import ReplayPathMap from '../map/ReplayPathMap';
import PositionsMap from '../map/PositionsMap';
import {formatPosition, formatSpeed, formatDistance} from '../common/formatter';
import ReportFilter from './ReportFilter';
import {useParams} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { formatDate } from '../common/formatter';
import {map} from "../map/Map";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {filteredIdActions} from '../store'
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    controlPanel: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%)',
    },
    controlContent: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
        boxShadow: 'none', // Remove any box shadow if needed
    },
    configForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    timeDisplay: {
        marginLeft: theme.spacing(2),
        fontStyle: 'italic',
        color: 'gray',
    },
    accordion: {
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
        boxShadow: 'none', // Remove any box shadow if needed
    },
    accordionSummary: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
    },
}));

const TimeLabel = ({ children, open, value }) => {
    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
};

const ReplayPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(true);
    const [positions, setPositions] = useState([]);
    const [index, setIndex] = useState(0);
    const [playing, setPlaying] = useState(false); // Add playing state
    const { id } = useParams();
    const [speedType, setSpeedType] = useState('Medium');
    const [open, setOpen] = useState(false);
    const [distanceAccu, setDistanceAccu] = useState(0);
    const [subPositions, setSubPositions] = useState([]);

    dispatch(filteredIdActions.updateFilteredId(id));

    const handleSubmit = async (deviceId, from, to, _, headers) => {
        const query = new URLSearchParams({ deviceId, from, to });

        const response = await fetch(`/api/positions?${query.toString()}`, { headers });
        if (response.ok) {
            setIndex(0);
            const res = await response.json();
            setPositions(res);
            setExpanded(false);
            if (res.length === 0)
            setOpen(true);
        }
    };

    const togglePlay = () => {
        setPlaying(!playing);
    };

    const goToPreviousPosition = () => {
        setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        const position = positions[index-1];
        map.flyTo({
            center: [position.longitude, position.latitude],
            zoom: [15],
            padding: { top: 5, bottom: 295, left: 25, right: 25 },
        });
    };

    const goToNextPosition = () => {
        setIndex((prevIndex) => (prevIndex < positions.length - 1 ? prevIndex + 1 : prevIndex));
        const position = positions[index+1];
        map.flyTo({
            center: [position.longitude, position.latitude],
            zoom: [15],
            padding: { top: 5, bottom: 295, left: 25, right: 25 },
        });
    };

    const speedValues = {
        Slow: 500,
        Medium: 300,
        Fast: 20
    };

    const handleToggleSpeed = () => {
        // Define the order of speed types
        const speedTypes = ['Slow', 'Medium', 'Fast'];

        // Find the current index of the speed type
        const currentIndex = speedTypes.indexOf(speedType);

        // Calculate the next index (looping back to the start if at the end)
        const nextIndex = (currentIndex + 1) % speedTypes.length;

        // Update the speed type
        setSpeedType(speedTypes[nextIndex]);
    };

    useEffect(() => {
        if (positions)
            setSubPositions(positions.slice(0, index+1))
        setDistanceAccu(index === 0 ? 0 : positions[index].attributes.totalDistance - positions[0].attributes.totalDistance);
    }, [index]);

    useEffect(() => {
        let intervalId;
        if (playing && index < positions.length - 1) {
            intervalId = setInterval(() => {
                setIndex((prevIndex) => (prevIndex < positions.length - 1 ? prevIndex + 1 : prevIndex));
            }, speedValues[speedType]); // Adjust the interval as needed
                const position = positions[index];
                map.flyTo({
                    center: [position.longitude, position.latitude],
                    zoom: [15],
                    padding: { top: 5, bottom: 295, left: 25, right: 25 },
                });
        }
        if (index === positions.length - 1) {
            setPlaying(false); // Stop playing when the last position is reached
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [playing, index, positions, speedType]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <div className={classes.root}>
            <MainToolbar />
            <Map>
                <ReplayPathMap allPositions={positions} positions={index === 0 ? positions : subPositions} index={index}/>
                {index < positions.length &&
                    <PositionsMap positions={[positions[index]]} />
                }
            </Map>

            <Container maxWidth="sm" className={classes.controlPanel}>
                {!!positions.length &&
                    <Paper className={classes.controlContent}>
                        <Slider
                            max={positions.length - 1}
                            step={null}
                            marks={positions.map((_, index) => ({ value: index }))}
                            value={index}
                            onChange={(_, index) => setIndex(index)}
                            valueLabelDisplay="auto"
                            valueLabelFormat={i => i < positions.length ? formatPosition(positions[i], 'fixTime') : ''}
                            ValueLabelComponent={TimeLabel}
                        />
                        <div className={classes.controls}>
                        {`${index + 1}/${positions.length}`}
                        <IconButton onClick={goToPreviousPosition} disabled={playing || index <= 0}>
                            <FastRewindIcon />
                        </IconButton>
                        <IconButton onClick={togglePlay}>
                            {playing ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        <IconButton onClick={goToNextPosition} disabled={playing || index >= positions.length - 1}>
                            <FastForwardIcon />
                        </IconButton>
                            <IconButton onClick={handleToggleSpeed}>
                                <FastForwardIcon />
                                <Typography variant="body2" style={{ marginLeft: 4, fontStyle: 'italic', color: 'gray' }}>
                                {speedType}
                            </Typography>
                            </IconButton>
                            <Typography variant="body2" className={classes.timeDisplay}>
                            {formatDate(positions[index].fixTime)}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {formatSpeed(positions[index].speed, "kmhFix0")}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {/*{formatDistance(positions[index].attributes.distance, "km")}*/}
                                {formatDistance(distanceAccu, "km")}
                            </Typography>
                        </div>
                    </Paper>
                }
                <div>
                    <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                            <Typography align='center'>
                                {t('reportConfigure')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.configForm}>
                            <ReportFilter handleSubmit={handleSubmit} showOnly />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Container>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('replayNoData')}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ReplayPage;
